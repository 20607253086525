/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: 'AdsPress',
  // 网站标题
  siteTitle: 'AdsPress - 一站式网站营销平台',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    'AdsPress是当前最好的一站式网页营销平台，主营AI品类营销、软件推广等，为客户提供高效果的营销平台',
  // 网站关键字
  siteKeywords:
    'AdsPress,营销,AI营销,产品推广,网站推广,流量入驻',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about', '/portal'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: ''
}
